<template>
    <div>
        <van-uploader
            v-model="list"
            :before-read="beforeRead"
            :preview-full-image="false"
            :disabled="disabled"
            :deletable="!disabled"
            accept="*"
            @click-preview="clickPreview"
            :max-size="maxSize"
            :max-count="maxCount"
        >
            <template #preview-cover="{ url }">
                <video-to-img v-if="pathIsVideo(url)" :videoSrc="url" class="cover-img" />
            </template>
        </van-uploader>

        <van-popup v-model="previewShow" @close="resetVideo" close-on-click-overlay>
            <div class="toolbar">
                <div class="close-icon" @click="closePopup">
                    <van-icon name="cross" />
                </div>
            </div>
            <template v-if="previewFileUrl && previewShow">
                <video
                    v-if="pathIsVideo(previewFileUrl)"
                    ref="video"
                    :autoplay="false"
                    controls="controls"
                    class="preview-item"
                >
                    <source :src="previewFileUrl" type="video/mp4" />
                </video>
                <img v-else :src="previewFileUrl" class="preview-item" />
            </template>
        </van-popup>
    </div>
</template>

<script>
window.hossFileCompressStart = () => {
    this.$toast.loading({ message: "文件压缩中", duration: 0 });
};

import VideoToImg from "./VideoToImg.vue";
import Compressor from "compressorjs";
import { uploadFile } from "@/api/common.js";
export default {
    components: { VideoToImg },
    props: {
        value: {
            type: String,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        maxCount: {
            type: Number,
            default: 5,
        },
        maxSize: {
            type: Number,
            default: 1024 * 1024 * 50,
        },
    },
    data() {
        return {
            previewShow: false,
            previewFileUrl: null,
        };
    },
    computed: {
        list: {
            get() {
                if (this.value) {
                    return this.value.split(",").map((item) => {
                        return { url: item };
                    });
                } else {
                    return [];
                }
            },
            set(val) {
                let files = "";
                val.forEach((item) => {
                    files += files ? "," + item.url : item.url;
                });
                this.$emit("input", files);
            },
        },
    },
    methods: {
        async beforeRead(file) {
            const { name, type } = file;
            if (!this.pathIsVideo(name) && !this.fileIsImg(type)) {
                this.$toast("请选择图片或视频（视频请选择mp4，mov格式上传）");
                return Promise.reject(false);
            }

            if (this.fileIsImg(file.type)) {
                // 上传图片进行压缩
                this.$toast.loading("文件压缩中");
                file = await this.compress(file);
            }

            this.$toast.loading("文件上传中");
            const { value } = await uploadFile(file);
            this.$toast.clear();

            this.list = [...this.list, { url: value.path }];

            // 不进行vant组件的后续操作
            return Promise.reject(false);
        },

        clickPreview(file, detail) {
            this.previewFileUrl = file.url;
            console.log(file.url);
            this.$nextTick(() => {
                this.previewShow = true;
            });
        },
        closePopup() {
            this.previewShow = false;
            this.resetVideo();
        },

        // 重置视频
        resetVideo() {
            if (this.$refs.video) {
                this.$refs.video.pause();
            }
        },

        // 工具函数
        fileIsImg(type) {
            return type.includes("image");
        },

        pathIsVideo(path) {
            return /\.(MP4|mp4|MOV|mov)$/.test(path);
        },

        // 压缩图片
        compress(img, backType = "file", quality = 0.8) {
            return new Promise((resolve, reject) => {
                new Compressor(img, {
                    quality,
                    success(result) {
                        let file = new File([result], img.name, { type: img.type });

                        if (!backType || backType == "blob") {
                            resolve(result);
                        } else if (backType == "file") {
                            resolve(file);
                        } else {
                            resolve(file);
                        }
                    },
                    error(err) {
                        this.$toast.fail("压缩失败");
                        reject(err);
                    },
                });
            });
        },
    },
};
</script>

<style scoped lang="less">
.van-uploader {
    /deep/.van-uploader__preview {
        border-radius: 4px;
        overflow: hidden;
    }

    /deep/.van-uploader__preview-cover {
        background-color: rgba(0, 0, 0, 0.8);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .cover-img {
        object-fit: cover;
        height: 100%;
    }
    /deep/.van-uploader__upload,
    /deep/.van-uploader__preview,
    /deep/.van-image__error,
    /deep/.van-image__loading {
        background-color: var(--separatorColor);
    }
    /deep/.van-uploader__wrapper--disabled {
        opacity: 1;
    }
}

.van-popup {
    background-color: transparent;
    height: 100vh;
    display: flex;
    align-items: center;

    .toolbar {
        position: fixed;
        z-index: 999;
        top: 30px;
        left: 0;
        width: 100%;
        color: #fff;
        font-size: 16px;

        & > div {
            text-align: center;
            display: inline-block;
            background-color: #000;
            padding: 4px 10px;
            border-radius: 8px;
        }

        .close-icon {
            position: absolute;
            top: 0;
            right: 20px;
        }
    }

    .preview-item {
        display: block;
        width: 100vw;
        max-height: 100vh;
        object-fit: contain;
    }
}
</style>

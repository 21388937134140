<script>
export default {
    props: {
        videoSrc: {
            type: String,
            default: "",
        },
    },
    watch: {
        videoSrc: {
            async handler(val) {
                if (!val) return "";
                const res = await this.getVideoBase64(val);
                this.imgSrc = res;
            },
            immediate: true,
        },
    },
    data() {
        return {
            imgSrc: "",
        };
    },
    render() {
        if (this.imgSrc) {
            return <img src={this.imgSrc} />;
        } else {
            return (
                <div class="preview-placeholder">
                    <van-icon name="play-circle-o" />
                </div>
            );
        }
    },
    methods: {
        // 获取视频第一帧
        getVideoBase64(url) {
            return new Promise(function (resolve, reject) {
                const video = document.createElement("video");
                video.setAttribute("crossOrigin", "Anonymous"); // 处理跨域
                video.setAttribute("src", url);
                video.setAttribute("preload", "auto");
                video.addEventListener("loadeddata", function () {
                    const canvas = document.createElement("canvas");
                    // canvas的尺寸和设置的视频宽高一样
                    const width = video.videoWidth;
                    const height = video.videoHeight;
                    canvas.width = width;
                    canvas.height = height;
                    canvas.getContext("2d").drawImage(video, 0, 0, width, height); // 绘制canvas
                    const dataURL = canvas.toDataURL("image/jpeg"); // 转换为base64
                    resolve(dataURL);
                });
            });
        },
    },
};
</script>

<style lang="less" scoped>
.preview-placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 36px;
    color: var(--separatorColor);
}
</style>
